import React from 'react'

import Layout from '../../../components/Layout'
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepSix extends React.Component {

  render() {
    return (
      <div>        
        <Layout>
          <CaseStudyStep
            title="Conclusion"
            body={[
              <div className="aga-three-stack">
                <div className="aga-single-item">You analyzed therapeutic IBD options for this patient in the context of treatment guidelines and algorithms</div>
                <div className="aga-single-item">You applied knowledge of IBD medications to tailor therapy to the individual patient</div>
                <div className="aga-single-item">You implemented evidence-based methods and patient education to help optimize IBD treatment strategies</div>
              </div>
            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/steps/step-6"
            backText="Back to Step 6"
            nextLink="/cme"
            nextText="Go to CME"
          />
        </Layout>
      </div>
    )
  }
}
